import React from 'react';
import { graphql } from 'gatsby';

import SEO from 'src/components/Seo/Seo';
import CardsGrid from 'src/components/CardsGrid';
import Banner from 'src/components/Banner';
import { HeadingScope } from 'src/components/Heading';
import Main from 'src/components/Main';
import {
  categoryFields,
  links,
} from 'src/components/MultistepForm/shared/constants';
import TravelCard from 'src/components/TravelCard';
import Grid from 'src/components/Grid/Grid';
import { getJoinedArrayByComma, isArrayEmpty } from 'src/utils/helpers';

const ProductCategoryPage: React.FC = ({
  data: { wpProductCategory: category },
}: any) => {
  const isParentCategory = !category.wpParent;

  const getChildCategoriesData = () => {
    const {
      wpChildren: { nodes: childCategories },
    } = category;

    return childCategories.map(childCategory => ({
      image: childCategory.image?.localFile.childImageSharp?.gatsbyImageData,
      title: childCategory?.name,
      link: `/${category.slug}/${childCategory.slug}`,
      button: true,
    }));
  };

  const getProductCountry = (product: any): string => {
    const countries = product.productCategories?.nodes
      .filter(
        category => category.wpParent?.node.slug === categoryFields.COUNTRIES
      )
      .map(country => country.name);

    return !isArrayEmpty(countries) ? getJoinedArrayByComma(countries) : '';
  };

  const getCategoryProductsData = () => {
    const {
      products: { nodes: products },
    } = category;

    return products.map(product => ({
      image: product.image,
      title: product.name ?? '',
      link: `${links.TRIPS}/${product.slug}`,
      price: product.productFields.fullPrice
        ? product.productFields.fullPrice
        : product.regularPrice,
      salePrice: product.productFields.fullPriceWithDiscount
        ? product.productFields.fullPriceWithDiscount
        : product.salePrice,
      location: getProductCountry(product),
      duration: `${product.productFields?.days} d.`,
    }));
  };

  return (
    <HeadingScope>
      <SEO
        title={category.seo?.title}
        description={category.seo?.metaDesc}
        image={category.seo?.opengraphImage?.localFile}
        withTitlePrefix
      />
      <Banner
        image={category.image?.localFile.childImageSharp?.gatsbyImageData}
        title={category.name}
        subtitle={category.seo?.metaDesc}
      />

      <Main>
        <>
          {isParentCategory ? (
            <CardsGrid columns={4} cards={getChildCategoriesData()} />
          ) : (
            <Grid columns={4}>
              {getCategoryProductsData().map(product => (
                <TravelCard
                  key={product.title}
                  url={product.link}
                  image={product.image}
                  location={product.location}
                  duration={product.duration}
                  title={product.title}
                  regularPrice={product.price}
                  salePrice={product.salePrice}
                />
              ))}
            </Grid>
          )}
        </>
        <div
          style={{ marginTop: '30px' }}
          dangerouslySetInnerHTML={{ __html: category.description }}
        />
      </Main>
    </HeadingScope>
  );
};

export const query = graphql`
  query Category($slug: String) {
    wpProductCategory(slug: { eq: $slug }) {
      ...YoastSeoProductCategory
      name
      slug
      description
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      products {
        nodes {
          ... on WpSimpleProduct {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 720)
                }
              }
            }
            productFields {
              fullPrice
              fullPriceWithDiscount
              days
            }
            productCategories {
              nodes {
                name
                wpParent {
                  node {
                    slug
                  }
                }
              }
            }
            name
            slug
            regularPrice
            salePrice
          }
        }
      }
      wpParent {
        node {
          slug
        }
      }
      wpChildren {
        nodes {
          slug
          name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 520)
              }
            }
          }
        }
      }
    }
  }
`;

export default ProductCategoryPage;
